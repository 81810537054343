import React from 'react'
import styled from 'styled-components'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {DevicesMax, SectionDescription, SectionTitle} from '../appearance/styled'
import {ROLES} from '../constants'
import {graphql} from 'gatsby'
import {getDirection} from '../helpers/utils'
import Page from '../components/Layout/Page'

const getComponentDirection = (direction, i) => {
    if (i % 2 === 0) {
        return direction === 'rtl' ? 'rtl' : 'ltr'
    }
    return direction === 'rtl' ? 'ltr' : 'rtl'
}

const WhatWeDo = ({...p}) => {
    const {language, t} = useI18next()
    const direction = getDirection(language)
    return (
        <Page {...p}>
            <SectionsContainer>
                {ROLES.map(({name, src}, i) => {
                    const title = t('what_we_do.' + name + '.title')
                    const description = t('what_we_do.' + name + '.description')
                    const componentDirection = getComponentDirection(direction, i)
                    return (
                        <Section key={name + '_' + i} id={name} dir={componentDirection}>
                            <SectionText>
                                <Title lang={language}>{title}</Title>
                                <Description lang={language}>{description}</Description>
                            </SectionText>
                            <SectionImage src={src} />
                        </Section>
                    )
                })}
            </SectionsContainer>
        </Page>
    )
}

export default WhatWeDo

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`

const SectionsContainer = styled.div``
const Section = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    position: relative;

    @media ${DevicesMax.mobile} {
        min-height: 150px;
        margin-bottom: 60px;
        padding-bottom: 20px;
    }
`
const SectionText = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-inline: 20px;

    @media ${DevicesMax.mobile} {
    }
`
const Title = styled(SectionTitle)``
const Description = styled(SectionDescription)``

const SectionImage = styled.img`
    max-height: 200px;
    @media ${DevicesMax.mobile} {
        position: absolute;
        opacity: 0.2;
        bottom: 0;
        right: 0;
    }
`
